import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Query, SanityBlogTagConnection } from "@graphql-types";
import SEO from "@shared/seo";
import { PageContext } from "@util/types";
import { Hero } from "@components";
import FooterSections from "@shared/footer/footerSections";
import { ButtonWrapper, Container, DesktopContainer, MobileContainer, PageWidth, Section } from "@util/standard";
import { fontWeights, colorsRGB, MOBILE_BREAKPOINT, TABLET_BREAKPOINT, SMALL_LAPTOP_BREAKPOINT, SMALLER_MOBILE, colors } from "@util/constants";
import { ArticleCard, Button, Link } from "@global";
import { Chevron } from "@util/svg";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

interface MenuProps {
  category?: string;
  type?: string;
  blogSlug?: string;
  allSanityBlogTag: SanityBlogTagConnection;
}

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 90px;
  grid-template-columns: 1fr 3fr;
  margin: auto;
  width: 100%;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    grid-gap: 40px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: ${SMALLER_MOBILE}px) {
    max-width: 350px;
  }
`;

const Select = styled.div`
  width: 100%;

  button{
    border: 2px solid ${colors.main};
    border-radius: 5px;
    width: 100%;
    padding: 13px 20px;
    cursor: pointer;
    text-align: left;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 7L11 1' stroke='currentColor'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 23px);
  }
`;

const SelectWrapper = styled.ul<{ display: boolean; }>`
  width: 100%;
  position: absolute;
  top: calc(100% - 5px);
  left: 0;
  background: ${colors.white};
  width: 100%;
  z-index: 2;
  border: 2px solid ${colors.main};

  ${({ display }) => display ? `
  ` : `
    opacity: 0;
    pointer-events: none;
  `}

  a {
    padding: 10px 20px;
    transition: background-color 0.3s ease;

    &:hover{
      background-color: ${colorsRGB.main(0.1)};
    }
  }
`;

const CategoryWrapper = styled.ul`
  li {
    margin-bottom: 15px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const TypeWrapper = styled.ul`
  margin-top: 20px;
  width: 100%;

  li{
    border-bottom: 2.5px solid ${colorsRGB.main(0.1)};
    &:last-of-type {
      border-bottom: 0;
    }
  }

  a {
    padding: 30px 0;
  }
`;

const LinkStyled = styled(Link) <{ isCurrent: boolean; }>`
  ${({ isCurrent }) => isCurrent && `
    font-weight: ${fontWeights.bold};
    pointer-events: none;
  `}
`;

const ArticleWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 100px 35px;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-gap: 60px;
    grid-template-columns: 1fr;
  }
`;

const ButtonStyled = styled.button<{ isCurrent?: boolean; }>`
  padding: 8px;
  ${({ isCurrent }) => isCurrent && `
    font-weight: ${fontWeights.bold};
  `}
`;

const Menu = ({ category, type, blogSlug, allSanityBlogTag }: MenuProps) => {
  return (
    <>
      <li>
        <LinkStyled internalSlug={category ? `/${blogSlug}/${category}` : `/${blogSlug}`} linkText="All" width="100%" isCurrent={!Boolean(type)} />
      </li>

      {allSanityBlogTag.nodes && allSanityBlogTag.nodes.map(tag => {
        if (!tag) return;

        const { tagType, slug, title } = tag;

        if (tagType !== "type") return;

        const current = slug?.current === type;
        const link = category ? `/${blogSlug}/${slug?.current}/${category}` : `/${blogSlug}/${slug?.current}`;

        return (
          <li key={tag._id}>
            <LinkStyled internalSlug={link} linkText={title} width="100%" isCurrent={current} />
          </li>
        );
      })}
    </>
  );
}

export default function BlogLandingTemplate({ data, pageContext }: Props) {
  const articlesPerPage = 10;
  const [selectOpen, setSelectOpen] = useState(false);
  const [paginateIndex, setPaginateIndex] = useState(articlesPerPage);


  if (!data?.sanityLockedPage) return null;
  const { sanityLockedPage: { pageMeta }, allSanityBlogInner: { nodes, totalCount }, allSanityBlogTag } = data;

  if (!pageMeta || !nodes || !allSanityBlogTag) return null;

  const paginateTotal = Math.ceil(totalCount / articlesPerPage);
  const blogSlug = pageContext.slug;
  const type = pageContext.filter?.types?.elemMatch.slug.current.eq;
  const category = pageContext.filter?.categories?.elemMatch.slug.current.eq;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />
      <Hero data={pageMeta.pageHero} type="know" onClick={() => selectOpen && setSelectOpen(false)} />
      <Section onClick={() => selectOpen && setSelectOpen(false)}>
        <PageWidth>
          <GridWrapper>
            <div>
              <MobileContainer position="relative" margin="0 0 15px">
                <Select>
                  <button onClick={() => setSelectOpen(!selectOpen)}>Sort by</button>
                </Select>
                <SelectWrapper display={selectOpen}>
                  <Menu category={category} blogSlug={blogSlug} type={type} allSanityBlogTag={allSanityBlogTag} />
                </SelectWrapper>
              </MobileContainer>
              <CategoryWrapper>
                {allSanityBlogTag.nodes && allSanityBlogTag.nodes.map(tag => {
                  if (!tag) return;

                  const { tagType, slug, title } = tag;

                  if (tagType !== "category") return;

                  const current = slug?.current === category;
                  const link = current ? (type ? `/${blogSlug}/${type}` : `/${blogSlug}`) : (type ? `/${blogSlug}/${type}/${slug?.current}` : `/${blogSlug}/${slug?.current}`);

                  return (
                    <li key={tag._id}>
                      <Button internalSlug={link} linkText={`Are you ${title}?`} theme={current ? "base" : "baseOutline"} width="100%" />
                    </li>
                  );
                })}
              </CategoryWrapper>
              <DesktopContainer>
                <TypeWrapper>
                  <Menu category={category} blogSlug={blogSlug} type={type} allSanityBlogTag={allSanityBlogTag} />
                </TypeWrapper>
              </DesktopContainer>
            </div>
            <Container flexDirection="column" alignItems="center">
              {nodes.length > 0 ?
                <>
                  <ArticleWrapper>
                    {nodes.slice(paginateIndex - articlesPerPage, paginateIndex).map(node => {
                      if (!node) return;

                      return (
                        <ArticleCard key={node._id} content={node} />
                      );
                    })}
                  </ArticleWrapper>
                  {paginateTotal > 1 &&
                    <ButtonWrapper margin="100px 0 0" tabletMargin="50px 0 0">
                      <ButtonStyled
                        onClick={() => {
                          setPaginateIndex(1 * articlesPerPage)
                        }}
                      >
                        <Chevron type="left" color="current" />
                      </ButtonStyled>
                      {[...Array(paginateTotal).keys()].map((i) => {
                        const index = i + 1;

                        return (
                          <ButtonStyled
                            key={`blogLandingPage-${i}`}
                            isCurrent={(paginateIndex / articlesPerPage) === index}
                            onClick={() => {
                              setPaginateIndex(index * articlesPerPage)
                            }}
                          >{index}</ButtonStyled>
                        );
                      })}
                      <ButtonStyled
                        onClick={() => {
                          setPaginateIndex(paginateTotal * articlesPerPage)
                        }}
                      >
                        <Chevron type="right" color="current" />
                      </ButtonStyled>
                    </ButtonWrapper>
                  }
                </>
                :
                <>No articles found.</>
              }
            </Container>
          </GridWrapper>
        </PageWidth>
      </Section>
      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
}

export const query = graphql`
  query BlogLandingQuery($pageId: String, $filter: SanityBlogInnerFilterInput) {
    sanityLockedPage(_id: { regex: $pageId }) {
      pageMeta {
        ...sanityPageMeta
      }
    }
    allSanityBlogInner(
      filter: $filter,
      sort: {order: DESC, fields: publishedDate}
    ) {
      nodes {
        ...sanityFeaturedBlogInner
      }
      totalCount
    }
    allSanityBlogTag {
      nodes {
        _id
        tagType
        slug {
          current
        }
        title
      }
    }
  }
`;
